import * as SentryBrowser from '@sentry/browser'

const {
  NODE_ENV,
  LOG_MOCKS,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENV,
  REACT_APP_SENTRY_VERSION,
} = process.env

function logMock(...args) {
  if (LOG_MOCKS) {
    // eslint-disable-next-line no-console
    console.log(...args)
  }
}

const SentryMock = {
  captureException: (...params) =>
    NODE_ENV === 'development'
      ? logMock('Sentry.captureException:', params)
      : () => {},
  init: (...params) =>
    NODE_ENV === 'development' ? logMock('Sentry.init:', params) : () => {},
  setUser: (...params) =>
    NODE_ENV === 'development' ? logMock('Sentry.setUser:', params) : () => {},
}

const Sentry = REACT_APP_SENTRY_DSN ? SentryBrowser : SentryMock

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_SENTRY_ENV,
  release: REACT_APP_SENTRY_VERSION,
})

export default Sentry
