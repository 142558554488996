import './ga'
import './sentry'

import 'webrtc-adapter'

import React from 'react'
import ReactDOM from 'react-dom'

import CircularProgress from '@material-ui/core/CircularProgress'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'

const styles = {
  applicationLoadingIndicatorWrapper: {
    minHeight: '100vh',
  },
}

const ApplicationLoadingIndicator = () => (
  <React.Fragment>
    <CssBaseline />

    <Grid
      alignItems="center"
      container
      justify="center"
      direction="column"
      spacing={0}
      style={styles.applicationLoadingIndicatorWrapper}
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  </React.Fragment>
)

const LazyApplication = React.lazy(() => import('./Application'))

const AsynchronousApplication = () => (
  <React.Suspense fallback={<ApplicationLoadingIndicator />}>
    <LazyApplication />
  </React.Suspense>
)

ReactDOM.render(
  <AsynchronousApplication />,
  global.document.getElementById('root'),
)
